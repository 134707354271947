import {ENABLE_GRID_OVERLAY, ENABLE_SUPERCHAT_MODAL} from 'config/index';
import {useRouter} from 'next/router';
// import Script from 'next/script';
import {ReactNode, useEffect, useState} from 'react';

import {generateWrapperConfig} from 'utils/strapi-components/getComponent';

import {Box} from 'components/basic-components';
import BPR from 'components/section-components/bpr/BestPriceRechner';

// import BackToTopButton from '../BackToTopButton';
import {DiagonalWrapper} from '../DiagonalWrapper/DiagonalWrapper';
import Footer from '../Footer';
import FooterSmall from '../FooterSmall';
import GridOverlay from '../GridOverlay/GridOverlay';
import Navbar from '../Navbar';
import FeedbackDialogue from "components/layout-components/FeedbackDialogue";

const wrapperDefaultConfig = generateWrapperConfig();
export interface ITranslatedSlugs {
  locale: string;
  slug: string;
}
export const Layout = ({
  children,
  translatedSlugs,
  published = true,
  hideBPR = false,
  hideSuperChat = false,
}: {
  children: ReactNode;
  translatedSlugs: ITranslatedSlugs[];
  hideBPR?: boolean;
  published?: boolean;
  hideSuperChat?: boolean;
}) => {
  const router = useRouter();
  const {
    locale,
    query: {reservationId, slug},
  } = router;

  const [isFooterVisible, setIsFooterVisible] = useState(false);
  
  const [insertSuperchatScript, setInsertSuperchatScript] = useState(false);
  const [insertFeedback, setInsertFeedback] = useState(false);
  const [superchatWidgetSrc, setSuperchatWidgetSrc] = useState('https://widget.superchat.de/snippet.js?applicationKey=WCy6dv0z8xbl9ewOBqkAgnVOom');
  const localeDe = locale?.includes('de');

  const removeSuperchatStyle = () => {
    const styleTags = document.querySelectorAll('style');
    styleTags.forEach((styleTag) => {
      if (styleTag.innerHTML.includes('#superchat-widget.hidden')) {
        styleTag.remove();
      }
    });
  }

  const removeExistingScript = () => {
    const existingScript = document.getElementById('superchatScript');
    const superchatWidget = document.getElementById('superchat-widget');
    const superchatSdk = document.querySelector('script[src="https://widget.superchat.de/sdk.js"]');
    if (superchatWidget) {
      superchatWidget.parentNode?.removeChild(superchatWidget);
    }
    if (existingScript) {
      existingScript.remove();
    }
    if (superchatSdk) {
      superchatSdk.parentNode?.removeChild(superchatSdk);
    }
    removeSuperchatStyle();
  };

  useEffect(() => {
    const currentSuperchatWidgetSrc = slug?.includes('autoabo')
      ? 'https://widget.superchat.de/snippet.js?applicationKey=WCl0vXzQKwDEnndAEGy7j8amY4'
      : 'https://widget.superchat.de/snippet.js?applicationKey=WCy6dv0z8xbl9ewOBqkAgnVOom';
    if (superchatWidgetSrc !== currentSuperchatWidgetSrc) {
      setSuperchatWidgetSrc(currentSuperchatWidgetSrc);
    }
  }, [slug]);

  useEffect(() => {
    if (localeDe && insertSuperchatScript && !isFooterVisible) {
      removeExistingScript();

      const script = document.createElement('script');
      script.src = superchatWidgetSrc;
      script.async = true;
      script.id = 'superchatScript';
      script.referrerPolicy = 'no-referrer-when-downgrade';

      document.body.appendChild(script);
    } else {
      removeExistingScript();
    }

    return () => {
      removeExistingScript();
    };
  }, [localeDe, insertSuperchatScript, isFooterVisible, superchatWidgetSrc]);

  useEffect(() => {
    const superchatWidget = document.getElementById('superchat-widget');
    if (superchatWidget) {
      if (localeDe && insertSuperchatScript && !isFooterVisible) {
        superchatWidget.style.display = 'block';
      } else {
        superchatWidget.style.display = 'none';
      }
    }
  }, [localeDe, insertSuperchatScript, isFooterVisible]);

  useEffect(() => {
    if (!hideSuperChat) {
      setTimeout(() => {
        if (ENABLE_SUPERCHAT_MODAL) {
          setInsertSuperchatScript(true);
        }
      }, 10000);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
        setInsertFeedback(true);
    }, 10000);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const footerElement = document.getElementById('footer');
      if (footerElement) {
        const rect = footerElement.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight;
        setIsFooterVisible(isVisible);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {ENABLE_GRID_OVERLAY ? <GridOverlay /> : null}
      <Navbar translatedSlugs={translatedSlugs} />
      {hideBPR ? null : <BPR withSessionConfig key={(reservationId as string) || 'bpr'} />}
      {children}
      <DiagonalWrapper
        noPadding
        config={{...wrapperDefaultConfig, diagonal: {bottom: true, top: true}}}
        background={'yellow'}
      >
        <Footer />
      </DiagonalWrapper>
      {(insertFeedback && !isFooterVisible) ? <FeedbackDialogue /> : null} 
      {/* <BackToTopButton /> */}
      {!published ? (
        <Box sx={{bottom: 150, zIndex: 10000, position: 'fixed'}}>
          <div
            style={{
              background: 'white',
              display: 'flex',
              padding: 5,
              width: 'auto',
              color: 'red',
              border: '1px solid black',
            }}
          >
            Diese Page ist ein Draft
          </div>
        </Box>
      ) : null}
      <DiagonalWrapper
        config={{...wrapperDefaultConfig, diagonal: {bottom: false, top: true}}}
        background={'black'}
        noPadding
      >
        <FooterSmall />
      </DiagonalWrapper>
    </div>
  );
};
export default Layout;
